import EN from "@/locales/en";
import EVENTS_EN from "@/locales/events-en";
import EVENTS_FR from "@/locales/events-fr";
import FR from "@/locales/fr";
import RESOURCES_EN from "@/locales/resources-en";
import RESOURCES_FR from "@/locales/resources-fr";
import ROLES_EN from "@/locales/roles-en";
import ROLES_FR from "@/locales/roles-fr";
import STATUES_EN from "@/locales/statuses-en";
import STATUES_FR from "@/locales/statuses-fr";

const TRANSLATIONS = {
  en: {
    events: EVENTS_EN,
    resources: RESOURCES_EN,
    roles: ROLES_EN,
    statues: STATUES_EN,
    translation: EN,
  },
  fr: {
    events: EVENTS_FR,
    resources: RESOURCES_FR,
    roles: ROLES_FR,
    statues: STATUES_FR,
    translation: FR,
  },
};

export default TRANSLATIONS;
