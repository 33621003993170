import { ForwardedRef, forwardRef } from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

type LinkBehaviorProps = Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] };

const LinkBehavior = (props: LinkBehaviorProps, ref: ForwardedRef<HTMLAnchorElement>) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <RouterLink ref={ref} to={href} {...other} />;
};

export default forwardRef(LinkBehavior);
