const EVENTS_EN = {
  accepted: "Offer accepted by the client",
  article_non_recoverable: "Recovery - Location finished but machine not accessible",
  break_repair: "Significant breakages (min €500: glass - hood - arm)",
  breakdown: "On-site intervention request: flexible-pneumatic-failure",
  canceled: "Annulée",
  client_missing_payment: "Unpaid on the client's side - machine recovery",
  closed: "Closed",
  confirmed: "Order confirmed",
  damages_third_parties: "Legal damage claim",
  delay_in_delivery: "Delivery - Delay over 1h30 max 3h00",
  delivery_address: "Delivery: Address (incomplete/incorrect)",
  duration_change: "Extension/Cancellation",
  equipment_not_found: "Untraceable machine",
  forgetting_extend: "Recovery: Forgot to extend",
  forgetting_recover: "Recovery: Forgot to recover",
  from_date: "Order Start",
  huge_delay_in_delivery: "Delivery - Delay over 4h00",
  immobilisation: "Immobilization request",
  incompetent_operator: "Incompetent operator",
  keys: "Key - Code / loss- not communicated",
  late_amendment: "Late date modification",
  machine_delivery: "Delivery",
  machine_retrieval: "Retrieval",
  missing_accessory: "Missing accessory",
  no_show: "Delivery: No Show",
  open: "Open",
  other: "Other",
  quality_issue: "Quality - Equipment in bad condition",
  robbery_accessory: "Accessory theft",
  robbery_article: "Machine theft",
  sent: "Request received",
  small_breakages: "Small breakages (max €500: beacon/light / mirror)",
  to_date: "Order ended",
  undeclared_use: "Undeclared use",
  validated: "Booking request validated",
  weather: "Bad weather requests",
  wrong_machine: "Equipment error",
  wrong_reclassification: "Incorrect lessor reclassification",
} as const;

export default EVENTS_EN;
