const EVENTS_FR = {
  accepted: "Offre acceptée par le client",
  article_non_recoverable: "Récupération - Loc. terminée mais machine pas accessible",
  break_repair: "Casses importantes (min 500 € : vitre - capot - bras)",
  breakdown: "Demande intervention sur site : flexible-pneumatique-panne",
  canceled: "Annulée",
  client_missing_payment: "Impayés côté client - récupération de la machine",
  closed: "Fermé",
  confirmed: "Commande confirmée",
  damages_third_parties: "Sinistre-juridique",
  delay_in_delivery: "Livraison -Retard sup 1h30 max 3h00",
  delivery_address: "Livraison : Adresse (incomplète/ erronée)",
  duration_change: "Prolongation/ Annulation",
  equipment_not_found: "Machine introuvable",
  forgetting_extend: "Récupération : Oubli de prolongation",
  forgetting_recover: "Récupération : Oubli de récupération",
  from_date: "Début de prestation",
  huge_delay_in_delivery: "Livraison - Retard sup 4h00",
  immobilisation: "Demande d'immobilisation",
  incompetent_operator: "Opérateur incompétent",
  keys: "Clé - Code / perte- non communiqué",
  late_amendment: "Modification de dates tardive",
  machine_delivery: "Livraison",
  machine_retrieval: "Reprise",
  missing_accessory: "Accessoire manquant",
  no_show: "Livraison : No Show",
  open: "Ouvert",
  other: "Autre",
  quality_issue: "Qualité - Matériel en mauvais état",
  robbery_accessory: "Vol accessoire",
  robbery_article: "Vol machine",
  sent: "Demande reçue",
  small_breakages: "Petites Casses (max 500 € : Gyrophare / rétro)",
  to_date: "Fin de prestation",
  undeclared_use: "Utilisation non déclarée",
  validated: "Demande de location validée",
  weather: "Demandes d'intempéries",
  wrong_machine: "Erreur Matériel",
  wrong_reclassification: "Mauvais reclassement loueur",
} as const;

export default EVENTS_FR;
