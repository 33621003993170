const ROLES_EN = {
  admin: "Admin",
  companion: "Companion",
  comptable: "Accountant",
  gestionnaire: "Manager",
  manager: "Manager",
  membre: "Member",
  operative: "Operative",
} as const;

export default ROLES_EN;
