const ROLES_FR = {
  admin: "Admin",
  companion: "Compagnon",
  comptable: "Comptable",
  gestionnaire: "Gestionnaire",
  manager: "Manager",
  membre: "Membre",
  operative: "Operative",
} as const;

export default ROLES_FR;
