import type { LinkProps, ThemeOptions } from "@tracktor/design-system";
import LinkBehavior from "@/components/Navigation/LinkBehavior";

const theme: ThemeOptions = {
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
    },
    MuiSnackbar: {
      defaultProps: {
        anchorOrigin: {
          horizontal: "center",
          vertical: "bottom",
        },
      },
    },
  },
};

export default theme;
