import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import * as sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { DataGridProvider } from "@tracktor/data-grid";
import { SnackbarProvider } from "@tracktor/design-system";
import { GoogleTagManagerProvider, useGoogleTagManager } from "@tracktor/react-google-tag-manager";
import {
  InitializeAxiosConfig,
  InitializeDaysJSConfig,
  InitializeI18nConfig,
  InjectDependenciesProvider,
  QueryClientProviderWithConfig,
  InitializeSentryConfig,
  InitializeMapBoxConfig,
} from "@tracktor/shared-module";
import axios from "axios";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import i18next from "i18next";
import languageDetector from "i18next-browser-languagedetector";
import mapbox from "mapbox-gl";
import { initReactI18next } from "react-i18next";
import * as reactRouter from "react-router-dom";
import Theme from "@/components/Layouts/Theme";
import Router from "@/components/Utils/Router";
import TRANSLATIONS from "@/constants/translations";

const librairies = {
  axios,
  dayjs,
  gtm: {
    useGoogleTagManager,
  },
  i18: {
    i18next,
    initReactI18next,
    languageDetector,
  },
  mapbox,
  reactQuery: {
    QueryClient,
    QueryClientProvider,
  },
  reactRouter,
  sentry,
};

const App = () => (
  <InjectDependenciesProvider apiURL={import.meta.env.VITE_API_URL} libraries={librairies}>
    <InitializeDaysJSConfig plugin={[relativeTime, utc]} language={i18next?.language?.slice(0, 2)} />
    <InitializeAxiosConfig />
    <InitializeI18nConfig resources={TRANSLATIONS} />
    <InitializeSentryConfig dsn={import.meta.env.VITE_SENTRY_DSN} />
    <InitializeMapBoxConfig accessToken={import.meta.env.VITE_MAPBOX_ACCESS_TOKEN} />
    <GoogleTagManagerProvider id={import.meta.env.VITE_GOOGLE_TAG_MANAGER_ID}>
      <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18next?.language?.slice(0, 2)}>
        <DataGridProvider licence={import.meta.env.VITE_MUI_LICENSE_KEY}>
          <QueryClientProviderWithConfig>
            <Theme>
              <SnackbarProvider>
                <Router />
              </SnackbarProvider>
            </Theme>
          </QueryClientProviderWithConfig>
        </DataGridProvider>
      </LocalizationProvider>
    </GoogleTagManagerProvider>
  </InjectDependenciesProvider>
);

export default App;
