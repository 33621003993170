import { jwtDecode } from "jwt-decode";

interface PayloadToken {
  exp: number;
  sub: string;
  entity_id: number;
  scope: string[];
  sassClient: string;
}

const isPayloadToken = (obj: any): obj is PayloadToken =>
  typeof obj === "object" &&
  obj !== null &&
  typeof obj.exp === "number" &&
  typeof obj.sub === "string" &&
  typeof obj.entity_id === "number" &&
  Array.isArray(obj.scope) &&
  obj.scope.every((item: any) => typeof item === "string");

const decodeToken = (accessToken?: string): PayloadToken | null => {
  if (!accessToken) {
    return null;
  }

  const decode = jwtDecode(accessToken);
  if (isPayloadToken(decode)) {
    return decode;
  }
  return null;
};

export default decodeToken;
