import { ThemeProvider } from "@tracktor/design-system";
import { useLocalStorage } from "@tracktor/react-utils";
import type { PropsWithChildren } from "react";
import theme from "@/theme";

const Theme = ({ children }: PropsWithChildren) => {
  const [isDarkTheme] = useLocalStorage("isDarkTheme", false);

  return (
    <ThemeProvider
      theme={{
        ...theme,
        palette: {
          ...theme.palette,
          mode: isDarkTheme ? "dark" : "light",
        },
      }}
    >
      {children}
    </ThemeProvider>
  );
};

export default Theme;
